import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  constructor(private permissionsService: NgxPermissionsService, private router: Router) { }

  ngOnInit() {
    if (localStorage.getItem('jwtToken')) {
      var token = JSON.parse(localStorage.getItem('jwtToken'));
      const perm = token.user.permisos;
      this.permissionsService.loadPermissions(perm);
      this.router.navigate(['/dashboard']);
      //RENOVAR TOKEN SI YA VENCIO
    }
  }
}
