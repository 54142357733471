import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  apiUrl: string = 'https://admin.inmobiliarianieblas.com';
  permisosdisponibles = [
    'DASHBOARD',
    'USUARIOS',
    'PARQUES INDUSTRIALES',
    'NAVES INDUSTRIALES',
    'ALIADOS',
    'PORTADAS',
    'SLIDES',
    'NOTICIAS',
    'AVISO DE PRIVACIDAD'
  ];
}
