import { Component } from '@angular/core';

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  children?: ChildrenItems[];
  permission?: string;
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab?: string;
  type?: string;
  permission?: string;
  icontype: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Inicio',
    type: 'link',
    icontype: 'nc-icon nc-bank'
  },
  {
    path: '/catalogos/parques',
    title: 'Parques Industriales',
    type: 'link',
    icontype: 'fal fa-industry-alt',
    permission: "PARQUES INDUSTRIALES"
  },
  {
    path: '/catalogos/naves',
    title: 'Naves Industriales',
    type: 'link',
    icontype: 'fal fa-warehouse-alt',
    permission: "NAVES INDUSTRIALES"
  },
  {
    path: '/catalogos/usuarios',
    title: 'Usuarios',
    type: 'link',
    icontype: 'fal fa-users',
    permission: "USUARIOS"
  },
  {
    path: '/catalogos/aliados',
    title: 'Aliados',
    type: 'link',
    icontype: 'fad fa-users-class',
    permission: "ALIADOS"
  },
  {
    path: '/catalogos/portadas',
    title: 'Portadas',
    type: 'link',
    icontype: 'far fa-images',
    permission: "PORTADAS"
  },
  {
    path: '/catalogos/slides',
    title: 'Slides',
    type: 'link',
    icontype: 'fal fa-chalkboard',
    permission: "SLIDES"
  },
  {
    path: '/catalogos/noticias',
    title: 'NOTICIAS',
    type: 'link',
    icontype: 'fal fa-newspaper',
    permission: "NOTICIAS"
  },
  {
    path: '/catalogos/aviso-privacidad',
    title: 'AVISO DE PRIVACIDAD',
    type: 'link',
    icontype: 'fas fa-sticky-note',
    permission: "AVISO DE PRIVACIDAD"
  },
];

@Component({

  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})

export class SidebarComponent {
  public menuItems: any[];
  isNotMobileMenu() {
    if (window.outerWidth > 991) {
      return false;
    }
    return true;
  }
  private token = JSON.parse(localStorage.getItem('jwtToken'));
  public perm = this.token.user.permisos;
  public user = this.token.user;

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
}
